<template>
    <div class="main">
        <NeoEditorToolBar :editor="editor" :filesave="true" />
        <!-- <div class="document--loader" style="background-color:red;margin-top:300px">
      <documentLoader/>
    </div> -->
        <section class="content-section" style="margin-top: 110px">
            <div class="container" id="main-wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <div id="debug--area"></div>

                        <div id="editor" class="page" contenteditable="true"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Editor from "./editor.js";
import NeoEditorToolBar from "./toolbar";
import documentLoader from "./reportLoader";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Editor",
    components: {
        NeoEditorToolBar,
        documentLoader,
    },
    data() {
        return {
            editor: null,
            savedTemplates: [],
            templates: [],
            templateData: {
                name: "Unnamed Report",
                desc: "",
            },
        };
    },

    methods: {
        ...mapActions(["fetchAllSections"]),
        selectTemplate: function(index) {
            // If  a template is selected , we are in edit mode.

            this.templateEditMode = true;

            this.savedTemplateIndex = index;
            let parent = document.getElementById("editor").parentNode;
            var selectedTemplate = this.savedTemplates.filter((template) => template.id === index)[0];
            parent.innerHTML = selectedTemplate.html;

            this.templateData.name = selectedTemplate.name;
            this.templateData.desc = selectedTemplate.desc;
            // Reattach events??..
            this.editor.reattachEvents({headersFootersAllowEdit: true});
        },
        toggleFullscreen() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
        },
        opanSaveModal() {
            this.$refs["save_template"].show();
        },
        getSavedTemplates: async function() {
            await this.fetchAllSections();
            this.savedTemplates = this.sections;
        },
    },
    async mounted() {
        let that = this;
        this.editor = new Editor("editor", {
            headersFootersAllowEdit: true,
        });
        // this.editor.init();

        await that.getSavedTemplates();

        fetch("https://demo7939192.mockable.io/templates")
            .then((response) => response.json())
            .then((value) => {
                this.templates = value.data;
            });
    },
    computed: {
        filteredItems() {
            return this.savedTemplates.filter((item) => {
                return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            });
        },
        ...mapGetters(["sections"]),
    },
};
</script>

<style scoped lang="scss">
::v-deep {
    @import "~bootstrap/scss/bootstrap.scss";
    @import "~bootstrap-vue/src/index.scss";
}
.navigation {
    display: none !important;
}
.content-section {
    // margin-top: 179px  !important;
    padding: 10px;
    background: rgb(248, 249, 250);
}

@media screen and (max-width: 1528px) {
    .content-section {
        margin-top: 17em !important;
    }
}

@media screen and (max-width: 1059px) {
    .content-section {
        margin-top: 28em !important;
    }
}

#main-wrapper * {
    -webkit-print-color-adjust: exact !important;
    transition: 0.3s all;
}

#main-wrapper {
    position: relative;
}

#editor {
    width: 794px;
    background: rgb(248, 249, 250);
}

.page {
    background-color: #fff;
    width: 21cm;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    display: flex;
    flex-direction: column;
}
</style>
